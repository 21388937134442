/* Non page-builder elements */

const sectionElementCardQuery = `
  __typename
  sys {id}
  name
  cardSummary {
    json
  }
  mainImage {
    title
    description
    fileName
    size
    url
    width
    height
  }
  imageIcon {
    sys {id}
    name
    iconRef
  }
  redLink {
    sys {id}
    name
    url
  }
  iconsAndTextCollection {
    items {
      label
      iconRef
      iconSize
      appearance
      inverse
    }
  }
`;

const sectionElementVideoArticleQuery = `
  __typename
  sys {id}
  slug
  name
  videoSummary {
    json
  }
`;

const sectionPageArticleQuery = `
  featured
  __typename
  sys {
    id
    createdAt: firstPublishedAt
    updatedAt: publishedAt
  }
  publishingDate
  name
  slug
  summary
  seoTitle
  seoDescription
  readingTime
  contentType {
    name
    displayName
    isDefault
  }
  mainImage {
    description
    url
  }
  articleThumbnailImage {
    title
    image {
      height
      width
      url
      size
      title
      description
      fileName
    }
    focalPoint
  }
  categoryTagsCollection(limit: 3) {
    items {
      name
      displayName
    }
  }
  topicsCollection(limit: 3) {
    items {
      name
      displayName
      slugName
    }
  }
`;

const sectionElementArticleIndexQuery = `
  carouselContainer
  sys { id }
  desktopNumberOfColumns
  articlesCollection(limit: 9) {
    items {
      ... on PageArticle {
        ${sectionPageArticleQuery}
      }
      ... on SectionElementVideoArticle {
        ${sectionElementVideoArticleQuery}
      }
      ... on Card {
        ${sectionElementCardQuery}
      }
    }
  }
`;

const sectionElementHeaderQuery = `
  __typename
  name
  headerText
  appearance
  inverse
  justify
  level
  noMargin
  size
  weight
`;

const pageFooterQuery = `
  footerLinksCollection {
    items {
      linksCollection {
        items {
          sys {
            id
          }
          name
          url
        }
      }
    }
  }
  copyrightText
`;

const page404Query = `
  heading
  body {
    json
  }
`;

const navigationQuery = `
  __typename
  brandLink
  mainLinksCollection {
    items {
      url
      name
      sys {
        id
      }
    }
  }
  topLevelLinksCollection {
    items {
      url
      name
      sys {
        id
      }
      isSelected
    }
  }
`;

const sectionElementCtaButtonQuery = `
  __typename
  label
  mobileLabel
  url
  rank
  mobileViewRank
  inverse
  mobileViewInverse
  iconRef
  linkType
  tooltipLabel
  tooltipLabelColor
  tooltipBackgroundColor
  iconAlignment
  scrollToId
  customAttributeData
`;

const sectionElementRichTextQuery = `
  __typename
  sys {
    id
  }
  name
  text {
    json
  }
  color
  textAlign
`;

const pageArticleQuery = `
recommendedArticles: sectionElementTitledRecommendedArticlesCollection(
  preview: $preview,
  where: {contentfulMetadata: { tags: { id_contains_some: [$tags] }}}) {
  items {
      name
      contentCollection(limit: 10) {
          items {
              ... on SectionElementHeader {
                sys {
                  id
                }
                name
                headerText
                appearance
                inverse
                justify
                level
                noMargin
                weight
                size
                isLeading
                hasNoGutter
                fontWeight
                isCenterAligned
                isTrailing
              }
              ... on SectionElementCtaButtonRow {
                sys {
                  id
                }
                name
                buttonRowAlignment
                buttons: buttonsCollection {
                  items {
                    sys {
                      id
                    }
                    name
                    label
                    url
                    iconRef
                    tooltipLabel
                    tooltipLabelColor
                    tooltipBackgroundColor
                    iconAlignment
                    rank
                    linkType
                    seoEventData
                    buttonAlignment
                    customAttributesJson
                    customAttributeData
                    scrollToId
                  }
                }
            }
          }
      }
    }
  }
  articleIndex: sectionFilterableArticleIndexCollection(
    preview: $preview,
    where: {
    contentfulMetadata: {
      tags_exists: true
      tags: {
        id_contains_some:[$tags]
      }
    }}) {
    items {
      name
      slug
      homePageName
      externalPageName
      externalPageUrl
      filterContentTypeCollection(limit: 3) {
        items {
          name
          displayName
          slugName
          isDefault
        }
      }
    }
  }
  allFilterableArticles: pageArticleCollection(
    preview: $preview,
    limit: 50,
    where: {
      contentfulMetadata: {
        tags: {
          id_contains_some: [$tags]
        }
      }
    }) {
    items {
      __typename
      sys {
        id
        createdAt: firstPublishedAt
        updatedAt: publishedAt
      }
      publishingDate
      pageArticleName: name
      slug
      summary
      featured
      contentType {
        name
        displayName
        isDefault
      }
      mainImage {
        url
        description
      }
      body {
        json
      }
      categoryTagsCollection(limit:10){
        items {
          sys {
            id
          }
          name
        }
      }
      topicsCollection(limit:10) {
        items {
          sys {
            id
          }
          name
          displayName
          slugName
        }
      }
    }
  }
  pageBackgroundColorsCollection(
    preview: $preview,
    where: {
    contentfulMetadata: {
      tags_exists: true
      tags: {
        id_contains_some:[$tags]
      }
    }}) {
    items {
      name
      oddColor
      evenColor
    }
  }
  allContentfulConfigArticlePages: configArticlePagesCollection(preview: $preview,
    where: {
      contentfulMetadata: {
        tags_exists: true,
        tags: {id_contains_some: [$tags]
      }
    }
  }) {
    items {
      feedbackQuestionLabel
      positiveFeedbackLabel
      negativeFeedbackLabel
      feedbackThanksLabel
      readingTimeMinuteLabel
      displaySaveIcon
      saveLabelText
      stickyHeader {
        socialLinksText
        contentCollection {
          items {
            __typename
            ... on SectionElementCtaButton {
              ${sectionElementCtaButtonQuery}
            }
            ... on SectionElementRichText {
              ${sectionElementRichTextQuery}
            }
          }
        }
      }
    }
  }
  allContentfulSaveArticleModal: saveArticleModalCollection(preview: $preview,
    where: {
      contentfulMetadata: {
        tags_exists: true,
        tags: {id_contains_some: [$tags]
      }
    }
  }) {
    items {
      name
      header
      bodyText
      buttonText
      buttonUrl
      footerText
      footerLinkText
      footerLinkUrl
    }
  }
  contentfulSiteConfig: seoCollection(preview: $preview, where: {
    contentfulMetadata: {
      tags_exists: true
      tags: {
        id_contains_some:[$tags]
      }
    }}) {
    items {
      name
      sharingsocialMediaButtons
      areSocialMediaButtonsAtTop
      sharingsocialMediaButtonRowLabel
    }
  }
  pageArticle(preview: $preview, id: $id) {
    __typename
    sys {
      createdAt: firstPublishedAt
      updatedAt: publishedAt
    }
    publishingDate
    name
    slug
    summary
    seoTitle
    seoDescription
    backgroundColor
    contentType {
      name
      displayName
      isDefault
    }
    customMetadataCollection {
      items {
        __typename
      }
    }
    mainImage {
      url
      description
    }
    sharingsocialMediaImage {
      description
      url
    }
    articleTitle
    contentType {
      name
      displayName
      isDefault
    }
    readingTime
    readingTimeTooltipContent {
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
    quickSummary {
      title
      quickSummaryItemsCollection {
        items {
          itemText
          itemTextMobile
          itemTargetId
        }
      }
    }
    body {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentfulMetadata {
              tags {
                name
              }
            }
            title
            description
            contentType
            fileName
            url
            size
            width
            height
          }
        }
      }
    }
    categoryTagsCollection {
      items {
        sys {
          id
        }
        name
      }
    }
    topicsCollection {
      items {
        sys {
          id
        }
        name
        displayName
        slugName
      }
    }
    recommendedArticles: recommendedArticlesCollection {
      items {
          name
          sys {
            id
          }
          content: contentCollection {
            items {
                ... on SectionElementHeader {
                    name
                    headerText
                    appearance
                    inverse
                    justify
                    level
                    noMargin
                    weight
                    size
                    isLeading
                    hasNoGutter
                    fontWeight
                    isCenterAligned
                    isTrailing
                }
                ... on SectionElementCtaButtonRow {
                    name
                    buttonRowAlignment
                }
                ... on SectionElementArticleIndex {
                    name
                    carouselContainer
                    desktopNumberOfColumns
                    articles: articlesCollection(limit: 10) {
                      items {
                        ... on PageArticle {
                          name
                          slug
                          summary
                          seoTitle
                          seoDescription
                          featured
                          keywords
                        }
                      }
                    }
                }
            }
        }
      }
    }
    keywords
  }
`;

/* Page Builder Section Elements */
const sectionElementCtaButtonRowQuery = `
  __typename
  name
  buttonRowAlignment
  isMobileViewButtonOrderReversed
  buttonsCollection {
    items {
      ${sectionElementCtaButtonQuery}
    }
  }
`;

const sectionElementWs10LinkWithIcon = `
  __typename
  name
  desktopLabel
  desktopAppearance
  desktopInverse
  desktopUnderline
  mobileLabel
  mobileAppearance
  mobileInverse
  mobileUnderline
  iconName
  targetSectionId
`;

const sectionElementTitledArticleIndexQuery = `
  __typename
  name
  contentCollection(limit:10) {
    items {
      ... on SectionElementHeader {
        ${sectionElementHeaderQuery}
      }
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
      ... on SectionElementArticleIndex {
        ${sectionElementArticleIndexQuery}
      }
    }
  }
`;

/* Page Builder Sections */

const sectionHeroWithTextAndCtaQuery = `
  __typename
  sys {
    id
  }
  name
  mirrorContent
  responsiveImageCollectionCollection {
    items {
      imageName
      isMainImage
      maxWidth
      imageTitle {
        title
        url
        description
      }
      secondImage {
        url
      }
    }
  }
  textPosition
  size
  contentCollection {
    items {
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
      ... on SectionElementCtaButtonRow {
        ${sectionElementCtaButtonRowQuery}
      }
      ... on SectionElementWs10LinkWithIcon {
        ${sectionElementWs10LinkWithIcon}
      }
    }
  }
`;

const sectionTitledArticleIndexQuery = `
  __typename
  name
  slug
  contentCollection(limit:10) {
    items {
      ... on SectionElementTitledArticleIndex {
        ${sectionElementTitledArticleIndexQuery}
      }
    }
  }
`;

const sectionElementBreadcrumbQuery = `
  __typename
  name
  sys {
    id
  }
  contentCollection {
    items {
      name
      label
      href
      external
      sys {
        id
      }
    }
  }
`;

const sectionFeatureQuery = `
  __typename
  sys {
    id
  }
  name
  featureGridCollection {
    items {
      sys {
        id
      }
      iconRef
      name
      body
      linkSlug
      seoEventData
    }
  }
`;

const sectionTitledArticleIndexListQuery = `
  __typename
  name
  sys {id}
  slug
  contentCollection(limit: 1) {
    __typename
    items {
      contentCollection(limit: 4) {
        items {
          __typename
          ... on SectionElementArticleIndex {
            ${sectionElementArticleIndexQuery}
          }
          ... on SectionElementHeader {
            ${sectionElementHeaderQuery}
          }
          ... on SectionElementRichText {
            ${sectionElementRichTextQuery}
          }
          ... on SectionElementCtaButtonRow {
            ${sectionElementCtaButtonRowQuery}
          }
        }
      }
    }
  }
`;

const sectionCtaQuery = `
  __typename
  sys {
    id
  }
  name
  contentCollection {
    items {
      ... on SectionElementCtaButtonRow {
        ${sectionElementCtaButtonRowQuery}
      }
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
  }
`;

const sectionFilterableArticleIndexQuery = `
  sys {
    id
  }
  slug
  name
  seoTitle
  seoDescription
  homePageName
  externalPageName
  externalPageUrl
  uiTextNewestFilterButton
  uiTextMostPopularFilterButton
  uiTextAllFilterButton
  uiTextFilterByFilterButton
  uiTextFilterByCategories
  uiTextFilterApplied
  uiTextTimeFilterLabel
  uiTextContentTypeLabel
  uiTextCancelFilterButton
  uiTextApplyFilterButton
  uiTextSearchDefault
  uiTextTopicsDropdownDefault
  uiTextResultsText
  uiTextBackToTopLabel
  uiTextPaginationSeparator
  uiTextNoResults
  uiTextNoResultsDescription
  uiTextLinkClear
  filterSeoEventData
  articleIndexCollection(limit: 3) {
    items {
      ... on SectionTitledArticleIndexList {
        ${sectionTitledArticleIndexListQuery}
      }
    }
  }
  uiImageNoResultsFound {
    __typename
    description
    url
  }
  topicsCollection(limit: 3) {
    items {
      name
      displayName
    }
  }
  filterTimeCollection(limit: 3) {
    items {
      name
      displayName
      timeRangeInMinutes
    }
  }
  filterContentTypeCollection(limit: 3) {
    items {
      name
      displayName
      slugName
      isDefault
    }
  }
`;

const sectionElementInputFieldQuery = `
  __typename
  name
  label
  placeholder
  required
  requiredValidationMessage
  type
  typeValidationPattern
  typeValidationMessage
  fieldType
  rows
  options
  desktopWidth
  phoneWidth
  tabletWidth
  hint
`;

const sectionIconLinkBarQuery = `
  iconsCollection(limit: 12) {
    items {
      __typename
      name
      sys { id }
      label
      url
      iconRef
      iconSize
      appearance
      color
      inverse
    }
  }
`;

const sectionPageTitleAndSummary = `
  sys { id }
  __typename
  name
  title
  subTitle
  summaryText
`;

const sectionRedCampaignBannerQuery = `
  sys { id }
  __typename
  name
  title
  bodyText
  linkText
  bannerColor
  targetUrl
  backgroundColor
  label
  linkType
  scrollToId
  image {
    imageTitle {
      title
      url
      description
    }
    mobileBackgroundImage {
      title
      url
      description
    }
  }
  buttonRow {
    ${sectionElementCtaButtonRowQuery}
  }
`;

const sectionFormQuery = `
  sys { id }
  name
  slug
  seoEventData
  datePickerTitle
  datePickerPlaceholderText
  datePickerDateFormat
  timePickerTitle
  timePickerPlaceholderText
  timePickerCaption
  optionalFieldIndicatorText
  contentCollection(limit: 15) {
    items {
      __typename
      ... on SectionElementCtaButtonRow {
        ${sectionElementCtaButtonRowQuery}
      }
      ... on SectionElementHeader {
        ${sectionElementHeaderQuery}
      }
      ... on SectionElementInputField {
        ${sectionElementInputFieldQuery}
      }
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
      ... on SectionIconLinkBar {
        ${sectionIconLinkBarQuery}
      }
    }
  }
  operatingDaysCollection(limit: 5) {
    items {
      __typename
      sys {
        id
      }
      title
      day
      startTime
      endTime
    }
  }
`;

const sectionInfoQuery = `
  __typename
  sys {
    id
  }
  name
  summary
  image {
    url
    description
  }
  contentCollection(limit: 3) {
    items {
      __typename
      buttonRowAlignment
      buttonsCollection(limit: 3) {
        items {
          name
          label
          url
          iconRef
          rank
          linkType
          seoEventData
          buttonAlignment
        }
      }
    }
  }
`;

const sectionElementFeatureVideoQuery = `
  __typename
  url
`;

const sectionElementFeatureImageQuery = `
  __typename
  mainImage {
    title
    description
    url
  }
`;

const sectionFeatureCardRowQuery = `
  __typename
  name
  sys {
    id
  }
  contentCollection {
    items {
      __typename
      ... on SectionElementFeatureImage {
        ${sectionElementFeatureImageQuery}
      }
      ... on SectionElementFeatureVideo {
        ${sectionElementFeatureVideoQuery}
      }
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
  }
`;

const sectionStepperQuery = `
  __typename
  name
  sys { id }
  contentCollection {
    items {
      __typename
      sys { id }
      name
      label
      href
      status
    }
  }
`;

const sectionAccordionQuery = `
  __typename
  name
  title
  invertColourScheme
  sys { id }
  contentCollection {
    items {
      __typename
      sys { id }
      name
      heading
      accordionContent {
        json
      }
    }
  }
  buttonRow {
    ${sectionElementCtaButtonRowQuery}
  }
`;

const sectionCustomStepperQuery = `
  __typename
  sys { id }
  name
  title
  text
  buttonRow {
    ${sectionElementCtaButtonRowQuery}
  }
  contentCollection {
    items {
      title
      stepText
      stepImage {
        title
        description
        url
      }
    }
  }
`;

const sectionElementIconLinkQuery = `
  __typename
  sys {id}
  name
  url
  label
  iconRef
  color
  iconSize
  appearance
`;

const sectionElementFeatureTwoQuery = `
  __typename
  sys {id}
  isCentred
  name
  contentCollection(limit: 3) {
    items {
      __typename
      ... on SectionElementHeader {
        ${sectionElementHeaderQuery}
      }
      ... on SectionElementIconLink {
        ${sectionElementIconLinkQuery}
      }
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
  }
`;

const sectionElementFeatureItemCardQuery = `
  __typename
  sys {
    id
  }
  name
  header
  bodyText
  bodyImage {
    description
    url
  }
  urlLink
  nameLink
  orientation
  justify
`;

const sectionElementFeatureItemCardWithIconQuery = `
  __typename
  sys {
    id
  }
  name
  header
  bodyText
  urlLink
  iconRef
  label
`;

const sectionElementFeatureCardWithIconTitleQuery = `
  __typename
  sys {
    id
  }
  name
  title
`;

const sectionFeatureTwoQuery = `
  __typename
  name
  sys {
    id
  }
  backgroundColor
  contentCollection(limit: 10) {
    items {
      __typename
      ... on SectionElementCtaButtonRow {
        ${sectionElementCtaButtonRowQuery}
      }
      ... on SectionElementFeatureImage{
        ${sectionElementFeatureImageQuery}
      }
      ... on SectionElementHeader{
        ${sectionElementHeaderQuery}
      }
      ... on SectionElementFeatureTwo{
        ${sectionElementFeatureTwoQuery}
      }
      ... on SectionElementFeatureItemCard{
        ${sectionElementFeatureItemCardQuery}
      }
      ... on SectionElementFeatureItemCardWithIcon{
        ${sectionElementFeatureItemCardWithIconQuery}
      }
      ... on SectionElementFeatureCardWithIconTitle{
        ${sectionElementFeatureCardWithIconTitleQuery}
      }
      ... on SectionElementWs10LinkWithIcon{
        ${sectionElementWs10LinkWithIcon}
      }
    }
  }
`;

const sectionSearchMainPageQuery = `
  sys { id }
  __typename
  searchResultsPageSlug
  searchHeader
  searchInputField {
    placeholder
  }
  topSearchText {
    topSearchList
    headerTextOnError
    headerText
    errorDescription
  }
`;

const sectionSearchResultPageQuery = `
  sys { id }
  __typename
  searchTagText {
    languageErrorText
  }
  searchResultLimit
  paginationSliceCount
  searchMainPageSlug
  searchHeader
  searchTagText {
    tagsLimit
    resultForText
    tagDespText
    showLessText
    showMoreText
  }
  disclaimer {
    json
  }
  searchInputField {
    placeholder
  }
  upvotePopup {
    feedbackDesp
  }
  downvotePopup {
    downvoteOption1
    downvoteOption2
    downvoteOption3
    downvoteOption4 {
      label
      placeholder
    }
    button {
      seoEventData
      label
      rank
    }
    downvoteIcon {
      url
    }
    downvoteHeaderText
    downvoteDespText
    cardHeaderText
    cardContentCollection(limit:3) {
      items {
        mainImage {
          url
          description
        }
        cardHeading
        cardSummary
        button {
          label
          rank
          url
          seoEventData
        }
      }
    }
  }
  articlesSection {
    relatedArticlesList {
      sys { id }
      slug
      __typename
      contentCollection(limit:3) {
        items {
          contentCollection(limit:3) {
            __typename
            items {
              ... on SectionElementHeader {
                ${sectionElementHeaderQuery}
              }
              ... on SectionElementRichText {
                ${sectionElementRichTextQuery}
              }
              ... on SectionElementArticleIndex {
                __typename
              }
            }
          }
        }
      }
    }
  }
`;

const SectionTestimonialRowQuery = `
  __typename
  sys {
    id
  }
  name
  heading {
    ... on SectionElementHeader {
      ${sectionElementHeaderQuery}
    }
  }
  testimonials: testimonialsCollection {
    items {
      personName
      personJobTitle
      name
      sys {
        id
      }
      avatarImage {
        url
      }
      feedback {
        json
      }
    }
  }
`;

const sectionMapQuery = `
  __typename
  sys {
    id
  }
  name
  responsiveImageCollectionCollection {
    items {
      imageName
      isMainImage
      maxWidth
      imageTitle {
        title
        url
        description
      }
      secondImage {
        url
      }
      mobileBackgroundImage {
        url
      }
    }
  }
  contentCollection {
    items {
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
  }
`;

const sectionPromotionBandQuery = `
  __typename
  sys {
    id
  }
  name
  backgroundColor
  imageAlignment
  mainImage {
    description
    url
  }
  contentCollection {
    items {
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
  }
`;

const sectionVideoBandQuery = `
  __typename
  sys {
    id
  }
  name
  backgroundColor
  label
  videoSource
  videoSlug
  videoAlignment
  image {
    description
    url
  }
  contentCollection {
    items {
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
  }
  buttonRow {
    ${sectionElementCtaButtonRowQuery}
  }
`;

const sectionTestimonialRibbonRowQuery = `
  __typename
  sys {
    id
  }
  name
  backgroundColor
  testimonalAlignment
  contentCollection {
    items {
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
    }
  }
  testimonials: testimonialsCollection {
    items {
      personName
      personJobTitle
      name
      sys {
        id
      }
      avatarImage {
        url
      }
      feedback {
        json
      }
    }
  }
`;

const sectionPromotionBannerQuery = `
  __typename
  sys {
    id
  }
  name
  responsiveImageCollectionCollection {
    items {
      imageName
      isMainImage
      maxWidth
      imageTitle {
        title
        url
        description
      }
      secondImage {
        url
      }
      mobileBackgroundImage {
        url
      }
    }
  }
  contentCollection {
    items {
      ... on SectionElementRichText {
        ${sectionElementRichTextQuery}
      }
      ... on SectionElementCtaButtonRow {
        ${sectionElementCtaButtonRowQuery}
      }
    }
  }
`;

const sectionDigitalExpertsQuery = `
  __typename
  sys { id }
  name
  title
  backgroundColor
  description {
    ... on SectionElementRichText {
      ${sectionElementRichTextQuery}
    }
  }
  expertsCollection {
    items {
      name
      expertName
      expertSummary {
        ... on SectionElementRichText {
          ${sectionElementRichTextQuery}
        }
      }
      avatarImage {
        url
      }
    }
  }
  linkText
  targetUrl
`;

const sectionConfirmationContentQuery = `
  __typename
  sys { id }
  name
  header
  subheader
  bodyHeader
  bodyRichText {
    ... on SectionElementRichText {
      ${sectionElementRichTextQuery}
    }
  }
  iconRef
`;

const sectionElementStandardBannerQuery = `
  __typename
  sys { id }
  name
  appearance
  headingText
  headingLevel
  paragraph
  backgroundColor
  primaryButton {
    ... on SectionElementCtaButton {
      ${sectionElementCtaButtonQuery}
    }
  }
  secondaryButton {
    ... on SectionElementCtaButton {
      ${sectionElementCtaButtonQuery}
    }
  }
  backgroundImage {
    url
  }
  footnoteText
  footnoteLinkText
  footnoteLinkUrl
  bannerCarouselTitle
`;

const sectionBannerCarouselQuery = `
  __typename
  sys { id }
  name
  waitTime
  nextLabelText
  prevLabelText
  banners: bannersCollection {
    items {
      ${sectionElementStandardBannerQuery}
    }
  }
`;

const sectionMSBookingsFormQuery = `
  __typename
  sys { id }
  name
  src
`;

const getCurrentlyEditedEntryTagsQuery = `
  entryCollection(preview: $preview, where: {sys: {id: $id}}) {
    items {
        sys {
            id
        }
        contentfulMetadata {
            tags {
                id
            }
        }
    }
  }
`;

const getRecaptchaTokenQuery = `
  recaptchaTokenCollection(
    preview: $preview,
    where: {contentfulMetadata: { tags: { id_contains_some: [$tags] }}}
  ) {
    items {
      token
    }
  }
`;

const getSectionElementTagCategoryCollectionQuery = `
  sectionElementTagCategoryCollection(
    preview: $preview,
    where: {contentfulMetadata: { tags: { id_contains_some: [$tags] }}}
  ) {
    items {
      name
      slugName
    }
  }
`;

const pageBuilderTypeQuery = `
__typename
name
slug
seoTitle
seoDescription
oddColor
evenColor
sectionsCollection(limit:10) {
  items {
    __typename
  }
}
`;

export const pageBuilderDynamicQuery = sectionArray => {
  let baseQuery = `
  __typename
  name
  slug
  seoTitle
  seoDescription
  oddColor
  evenColor
  sectionsCollection(limit:10) {
    items {
      __typename
  `;

  let sectionTypesArray = sectionArray.map(section => section.__typename);
  sectionTypesArray = [...new Set(sectionTypesArray)];
  console.log(sectionTypesArray);
  sectionTypesArray.map(sectionType => {
    switch (sectionType) {
      case 'SectionHeroWithTextAndCta':
        baseQuery = baseQuery.concat(`
        ... on SectionHeroWithTextAndCta {
          ${sectionHeroWithTextAndCtaQuery}
        }
        `);
        break;
      case 'SectionElementBreadcrumb':
        baseQuery = baseQuery.concat(`
        ... on SectionElementBreadcrumb {
          ${sectionElementBreadcrumbQuery}
        }
        `);
        break;
      case 'SectionIconLinkBar':
        baseQuery = baseQuery.concat(`
        ... on SectionIconLinkBar {
          ${sectionIconLinkBarQuery}
        }
        `);
        break;
      case 'SectionTitledArticleIndexList':
        baseQuery = baseQuery.concat(`
        ... on SectionTitledArticleIndexList {
          ${sectionTitledArticleIndexListQuery}
        }
        `);
        break;
      case 'SectionFeatureTwo':
        baseQuery = baseQuery.concat(`
        ... on SectionFeatureTwo {
          ${sectionFeatureTwoQuery}
        }
        `);
        break;
      // There is an error in the sectionDigitalExpertsQuery, check api response for clues
      case 'SectionDigitalExperts':
        baseQuery = baseQuery.concat(`
          ... on SectionDigitalExperts {
            ${sectionDigitalExpertsQuery}
        }
        `);
        break;
      case 'SectionMap':
        baseQuery = baseQuery.concat(`
            ... on SectionMap {
              ${sectionMapQuery}
          }
        `);
        break;
      case 'SectionPromotionBand':
        baseQuery = baseQuery.concat(`
              ... on SectionPromotionBand {
                ${sectionPromotionBandQuery}
            }
          `);
        break;
      case 'SectionVideoBand':
        baseQuery = baseQuery.concat(`
              ... on SectionVideoBand {
                ${sectionVideoBandQuery}
            }
          `);
        break;
      case 'sectionTestimonialRibbonRowQuery':
        baseQuery = baseQuery.concat(`
                ... on SectionTestimonialRibbonRowQuery {
                  ${sectionTestimonialRibbonRowQuery}
              }
            `);
        break;

      case 'SectionTestimonialRow':
        baseQuery = baseQuery.concat(`
            ... on SectionTestimonialRow {
              ${SectionTestimonialRowQuery}
          }
        `);
        break;
      case 'SectionRedCampaignBanner':
        baseQuery = baseQuery.concat(`
          ... on SectionRedCampaignBanner {
            ${sectionRedCampaignBannerQuery}
          }
        `);
        break;
      case 'SectionAccordion':
        baseQuery = baseQuery.concat(`
            ... on SectionAccordion {
              ${sectionAccordionQuery}
          }
        `);
        break;
      case 'SectionStrategicPartners':
        baseQuery = baseQuery.concat(`
            ... on SectionStrategicPartners {
              sys {id}
          }
        `);
        break;
      case 'SectionForm':
        baseQuery = baseQuery.concat(`
        ... on SectionForm {
          ${sectionFormQuery}
        }
        `);
        break;
      case 'SectionPageTitleAndSummary':
        baseQuery = baseQuery.concat(`
          ... on SectionPageTitleAndSummary {
            ${sectionPageTitleAndSummary}
          }
        `);
        break;
      case 'SectionPromotionBanner':
        baseQuery = baseQuery.concat(`
          ... on SectionPromotionBanner {
            ${sectionPromotionBannerQuery}
          }
        `);
        break;
      case 'SectionConfirmationContent':
        baseQuery = baseQuery.concat(`
          ... on SectionConfirmationContent {
            ${sectionConfirmationContentQuery}
          }
        `);
        break;
      case 'SectionFilterableArticleIndex':
        baseQuery = baseQuery.concat(`
          ... on SectionFilterableArticleIndex {
            ${sectionFilterableArticleIndexQuery}
          }
        `);
        break;
      case 'SectionBannerCarousel':
        baseQuery = baseQuery.concat(`
            ... on SectionBannerCarousel {
              ${sectionBannerCarouselQuery}
            }
          `);
        break;
      case 'SectionCta':
        baseQuery = baseQuery.concat(`
            ... on SectionCta {
              ${sectionCtaQuery}
            }
          `);
        break;
      case 'SectionMsBookingsForm':
        baseQuery = baseQuery.concat(`
            ... on SectionMsBookingsForm {
                ${sectionMSBookingsFormQuery}
            }
          `);
        break;
      default:
        break;
    }
  });
  baseQuery = baseQuery.concat('}}');
  return baseQuery;
};

export const previewConfig = {
  pageBuilder: {
    query: pageBuilderTypeQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: false,
  },
  getCurrentlyEditedEntryTags: {
    query: getCurrentlyEditedEntryTagsQuery,
    useDefaultQueryWrapper: false,
    needsWrapper: false,
  },
  getRecaptchaToken: {
    query: getRecaptchaTokenQuery,
    useDefaultQueryWrapper: false,
    needsWrapper: false,
  },
  getSectionElementTagCategoryCollection: {
    query: getSectionElementTagCategoryCollectionQuery,
    useDefaultQueryWrapper: false,
    needsWrapper: false,
  },
  pageFooter: {
    query: pageFooterQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: false,
  },
  navigation: {
    query: navigationQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: false,
  },
  sectionElementTitledArticleIndex: {
    query: sectionElementTitledArticleIndexQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  sectionElementCard: {
    query: sectionElementCardQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  sectionElementCtaButton: {
    query: sectionElementCtaButtonQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  sectionElementCtaButtonRow: {
    query: sectionElementCtaButtonRowQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  sectionElementHeader: {
    query: sectionElementHeaderQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  sectionElementRichText: {
    query: sectionElementRichTextQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  sectionElementVideoArticle: {
    query: sectionElementVideoArticleQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  sectionHeroWithTextAndCta: {
    query: sectionHeroWithTextAndCtaQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: false,
  },
  sectionCustomStepper: {
    query: sectionCustomStepperQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: false,
  },
  sectionTitledArticleIndexList: {
    query: sectionTitledArticleIndexQuery,
    useDefaultQueryWrapper: true,
    needsWrapper: true,
  },
  pageArticle: {
    query: pageArticleQuery,
    useDefaultQueryWrapper: false,
    needsWrapper: false,
  },
  seo404Page: {
    query: page404Query,
    useDefaultQueryWrapper: true,
    needsWrapper: false,
  },
};
